<template>
    <div>
        <Header />
        <div class="wrapper">
            <div class="page-header clear-filter" filter-color="gray">
                <div class="page-header-image"></div>
                <div class="container">
                    <div class="content-center brand">
                        <!-- <h1 class="h1-seo" style="color: black;">Authentic Greek Cuisine</h1> -->
                        <!-- <h3 class="h3-seo"></h3> -->
                        <div class="content-center brand white-box pt-5 pb-4">
                            <h1>Welcome to our Kosmos</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div class="main">
                <div class="row mt-5 mb-5 text-center">
                    <div class="col-12">
                        <h2>Pickup & Delivery Available</h2>
                        <p class="px-4">We are now offering pickup for online orders</p>
                    </div>
                    <div class="col-lg-4"></div>
                    <div class="col-lg-4">
                        <a target="_blank" href="https://order.toasttab.com/online/kosmos-restaurant-944-main-street" class="btn btn-primary custom-btn mb-0 large-button">Online Order</a>
                    </div>
                    <div class="col-lg-4"></div>
                </div>
                <div class="row mt-5 mb-5">
                    <div class="col-lg-6">
                        <!-- <img src="../assets/olive-oil.png" /> -->
                        <div id="carousel" class="carousel slide" data-ride="carousel" data-interval="3000">
                                <ol class="carousel-indicators">
                                    <li data-target="#carousel" data-slide-to="0" class="active"></li>
                                    <li data-target="#carousel" data-slide-to="1"></li>
                                    <li data-target="#carousel" data-slide-to="2"></li>
                                    <li data-target="#carousel" data-slide-to="3"></li>
                                </ol>
                                <div class="carousel-inner rounded-corners-right">
                                    <div class="carousel-item active">
                                        <img class="d-block w-100 rounded-corners-right" src="../assets/dias.jpg" alt="Kosmos family restaurant">
                                    </div>
                                    <div class="carousel-item">
                                        <img class="d-block w-100 rounded-corners-right" src="../assets/kosmos-wma-family.jpg" alt="Kosmos family business">
                                    </div>
                                    <div class="carousel-item">
                                        <img class="d-block w-100 rounded-corners-right" src="../assets/kosmos-dinning-room.jpg" alt="Kosmos lounge area">
                                    </div>
                                    <div class="carousel-item">
                                        <img class="d-block w-100 rounded-corners-right" src="../assets/kosmos-sign.jpg" alt="Kosmos Walpole MA Main St">
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div class="col-lg-1"></div>
                    <div class="col-lg-4 text-center">
                       <h2 class="caligraphy mt-5">Our Story</h2>
                       <p class="caligraphy">
                            kosmos<br>
                            /kósmos/<br>
                            <i>noun</i><br>
                            world, people, universe<br>
                            κόσμος, άνθρωποι, σύμπαν
                       </p>
                       <p class="px-lg-0 px-5">
                            Kosmos brings you a world of authentic recipes from all over Greece here on Main St. in Walpole MA. Real Greek cuisine, once prepared
                            in our mothers' and grandmothers' kitchens now served on your table.
                            <br><br>
                            experience for yourself the world renowned<br>Greek hospitality.
                       </p>
                       <img style="width: 30%" class="pt-4 float-right signature" src="../assets/signature.png" />
                    </div>
                    <div class="col-lg-1"></div>
                </div>

                <div class="row veg-background pt-5 pb-5 mt-5 mb-5">
                    <div class="col-lg-6"></div>
                    <div class="col-lg-5 pt-sm-5">
                        <div class="card pb-5 menu-left">
                            <div class="card-body text-center">
                                <h2 class="caligraphy">Our Ever Evolving Menu</h2>
                                <p>Simple, authentic, Greek recipes and fresh ingredients is what inspires our menus</p>
                                <a href="/menu" class="btn btn-primary custom-btn mb-0 large-button">Our Menu</a>
                            </div>
                        </div>
                    </div> 
                    <div class="col-lg-1"></div>
                </div>

                <div class="row align-items-center">
                    <div class="col-lg-1"></div>
                    <div class="col-lg-4 text-center px-lg-0 px-5">
                       <h2 class="caligraphy mt-5">Need a reservation?</h2>
                       <p class="caligraphy">
                            Let us take care of your next special occasion! With plenty of options to match your budget and we will give your guests a unique dining experience!
                       </p>
                       <a target="_blank" href="https://www.opentable.com/r/kosmos-reservations-walpole?restref=1239994&lang=en-US&ot_source=Restaurant%20website" class="btn btn-primary custom-btn mb-0 large-button">Find Table</a>
                       <p class="px-lg-0 px-5">
                            
                       </p>
                    </div>
                    <div class="col-lg-1"></div>
                    <div class="col-lg-6 rounded-corners">
                        <img class="rounded-corners-left" src="../assets/potares.jpg" alt="Kosmos MA full table greek food"/>
                    </div>
                </div>

                <div class="row justify-content-center mb-0 mt-5">
                    <div class="col-12 text-center">
                        <h1 class="caligraphy menu-margins">Eat · Drink · Greek</h1>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-lg-4 col-md-12 mb-4 mb-lg-0 pr-1 pl-1">
                        <div class="zoom">
                            <img
                            src="../assets/paidakia.jpg"
                            class="w-100 shadow-1-strong rounded mb-2"
                            alt="kosmos walpole mass cocktails"
                            />
                        </div>

                        <div class="zoom">
                            <img
                            src="../assets/xtapodi.jpg"
                            class="w-100 shadow-1-strong rounded mb-2"
                            alt="kosmos walpole appetizers menu"
                            />
                        </div>
                    </div>

                    <div class="col-lg-4 mb-4 mb-lg-0 pr-1 pl-1">
                        <div class="zoom">
                            <img
                            src="../assets/ouzo-lemonade.jpg"
                            class="w-100 shadow-1-strong rounded mb-2"
                            alt="kosmos restaurant eat drink greek"
                            />
                        </div>
                        
                        <div class="zoom">
                            <img
                            src="../assets/ekmek.jpeg"
                            class="w-100 shadow-1-strong rounded mb-2"
                            alt="kosmos greek restaurant and bar, delicious desserts"
                            />
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12 mb-4 mb-lg-0 pr-1 pl-1">
                        <div class="zoom">
                            <img
                            src="../assets/kosmos-horiatiki.jpg"
                            class="w-100 shadow-1-strong rounded mb-2"
                            alt="kosmos traditional greek cuisine"
                            />
                        </div>

                        <div class="zoom">
                            <img
                            src="../assets/talagani.jpg"
                            class="w-100 shadow-1-strong rounded mb-2"
                            alt="kosmos full bar cocktails"
                            />
                        </div>
                    </div>
                </div>
                
                <div class="row align-items-center mt-5">
                    <div class="col-lg-6">
                        <img class="rounded-corners-right" src="../assets/dinning-room.jpg" alt="kosmos make your reservations today"/>
                    </div>
                    <div class="col-lg-1"></div>
                    <div class="col-lg-4 text-center">
                       <h2 class="caligraphy mt-5">Let us take care of<br>your special event</h2>
                       <p class="caligraphy px-lg-0 px-5">
                            Let us take care of your next special occasion! With plenty of options to match your budget and we will give your guests a unique dining experience!
                       </p>
                       <a href="/contact" class="btn btn-primary custom-btn mb-0 large-button">Inquire</a>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    </div>

    <!-- <div class="row">
        <div class="col-lg-6 text-center my-auto">
            <img class="logo" src="../assets/logo-cropped.jpg">
            <h2 class="soon" style="color: #949494">Coming Soon</h2>
            <h5 class="mt-5"><a style="color: blue;" href="mailto:info@kosmoswma.com">Contact Us</a></h5>
        </div>
         <div class="col-lg-6 bg-image">
            <img class="w-100" src="../assets/bg.jpg">
        </div>
    </div> -->
</template>

<script>
    export default {
        name: 'Home',
        components: {}
    }
</script>
