<template>
    <div>
        <Header />
        <div class="wrapper">
            <div class="main text-center menu-margins mb-5 border-top">
                <h1 class="mt-5 mb-0 caligraphy">Catering Menu</h1>
                <div>
                    <CateringMenu />
                </div>

                <p class="text-center font-weight-bold font-italic my-5">
                    <!-- Items marked can be prepared in these options<br>
                    <img class="menu-diet-icon" src="../assets/vegetarian-sign.png"/>vegetarian
                    <img class="menu-diet-icon" src="../assets/gluten-free-sign.png"/>gluten free
                    <img class="menu-diet-icon" src="../assets/vegan-sign.png"/>vegan<br> -->
                    <small>
                        Please inform us of all food allergies and dietary requirements; <br>
                        *Consuming raw or undercooked meats, poultry, seafood, shellfish or eggs may increase your risk of foodborne illness, especially if you have certain medical conditions<br><br>
                        The menu prices are subject to change without any warning
                    </small>
                </p>
            </div>
            <Footer />
        </div>
    </div>
</template>

<script>
import CateringMenu from '../components/Menus/CateringMenu';

export default {
    name: 'Menu',
    components: {
        CateringMenu,
    },
    data: function() {
        return {
            
        }
    },
}
</script>
