<template>
    <div>
        <Header />
        <SpecialOverlay v-if="showSpecials() === true"/>
        <div class="wrapper">
            <div class="main text-center menu-margins mb-5 border-top">
                <h1 class="mt-5 mb-0 caligraphy">Menu</h1>
                <a @click="showDinnerMenu()" style="color: white" class="btn large-button btn-primary mt-4">Dinner</a>
                <br>
                <a @click="showLunchMenu()" style="color: white" class="btn large-button btn-primary mt-4">Lunch Additions</a>
                <br>
                <a @click="showCocktailMenu()" style="color: white" class="btn large-button btn-primary mt-3">Cocktails</a>
                <div v-if="selectedMenu === 'dinner'">
                    <DinnerMenu />
                </div>

                <div v-if="selectedMenu === 'lunch'">
                    <LunchMenu />
                </div>

                <div v-if="selectedMenu === 'cocktails'">
                    <CocktailMenu />
                </div>

                <div v-if="selectedMenu === 'special'">
                    <SpecialMenu />
                </div>

                <p class="text-center font-weight-bold font-italic my-5">
                    <!-- Items marked can be prepared in these options<br>
                    <img class="menu-diet-icon" src="../assets/vegetarian-sign.png"/>vegetarian
                    <img class="menu-diet-icon" src="../assets/gluten-free-sign.png"/>gluten free
                    <img class="menu-diet-icon" src="../assets/vegan-sign.png"/>vegan<br> -->
                    <small>
                        Please inform us of all food allergies and dietary requirements; <br>
                        *Consuming raw or undercooked meats, poultry, seafood, shellfish or eggs may increase your risk of foodborne illness, especially if you have certain medical conditions<br><br>
                        The menu prices are subject to change without any warning
                    </small>
                </p>
            </div>
            <Footer />
        </div>
    </div>
</template>

<script>
import DinnerMenu from '../components/Menus/DinnerMenu';
import CocktailMenu from '../components/Menus/CocktailMenu';
import SpecialMenu from '../components/Menus/SpecialMenu';
import LunchMenu from '../components/Menus/LunchMenu';

export default {
    name: 'Menu',
    components: {
        DinnerMenu,
        CocktailMenu,
        SpecialMenu,
        LunchMenu
    },
    data: function() {
        return {
            selectedMenu: !this.$route.params.brunch ? "dinner" : "brunch"
        }
    },

    methods: {
        showSpecials() {
            const currentDate = new Date();
            let dayOfWeek = currentDate.getDay();

            // 5 - Friday, 6 - Saturday, 7 - Sunday
            if (dayOfWeek === 5 || dayOfWeek === 6 || dayOfWeek === 7) {
                return false;
            }
            
            return false;
        },

        showOverlay() {
            if (this.$route.params.brunch) {
                return false;
            }

            return true;
        },

        showDinnerMenu() {
            this.selectedMenu = "dinner"
        },

        showCocktailMenu() {
            this.selectedMenu = "cocktails"
        },

        showLunchMenu() {
            this.selectedMenu = "lunch"
        },

        showSpecialMenu() {
            this.selectedMenu = "special"
        }
    }
}
</script>
