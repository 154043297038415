<template>
    <div>
        <HeaderBanner />
        <nav class="navbar navbar-expand-lg navbar-gray">
            <div class="container">
                <div class="navbar-translate">
                    <a class="navbar-brand" href='/' data-placement="bottom">
                        <img class="logo" src="../assets/logo-header.png" alt="Kosmos Restaurant eat drink greek logo"/>
                    </a>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-bar top-bar"></span>
                        <span class="navbar-toggler-bar middle-bar"></span>
                        <span class="navbar-toggler-bar bottom-bar"></span>
                    </button>
                </div>
                
                <div class="collapse navbar-collapse justify-content-end" id="navigation" data-nav-image="./assets/img/blurred-image-1.jpg">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                        <a class="nav-link" target="_blank" href="https://www.opentable.com/r/kosmos-reservations-walpole?restref=1239994&lang=en-US&ot_source=Restaurant%20website">
                            <p>Reservations</p>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" href="/menu">
                            <p>Menu</p>
                        </a>
                    </li>

                    <!-- <li class="nav-item">
                        <a class="nav-link" href="/events">
                            <p>Events</p>
                        </a>
                    </li> -->

                    <li class="nav-item">
                        <a class="nav-link" href="/catering">
                            <p>Catering</p>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" href="https://order.toasttab.com/online/kosmos-restaurant-944-main-street">
                            <p>Online Pickup/Delivery</p>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" href="/contact">
                            <p>Contact</p>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" rel="tooltip" title="Like us on Facebook" data-placement="bottom" href="https://www.facebook.com/kosmoswalpole/" target="_blank">
                        <i class="fab fa-facebook-square"></i>
                            <p class="d-lg-none d-xl-none">Facebook</p>
                        </a>
                    </li>
                    
                    <li class="nav-item">
                        <a class="nav-link" rel="tooltip" title="Follow us on Instagram" data-placement="bottom" href="https://instagram.com/kosmos_ma?igshid=YzA2ZDJiZGQ=" target="_blank">
                        <i class="fab fa-instagram"></i>
                            <p class="d-lg-none d-xl-none">Instagram</p>
                        </a>
                    </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
export default {
    name: 'Header',
    data: function() {
        return {
            showModal: true
        }
    },
    // mounted() {
    //     const script = document.createElement('script');
    //     script.src = "//www.opentable.com/widget/reservation/loader?rid=1239994&type=standard&theme=standard&color=1&dark=false&iframe=true&domain=com&lang=en-US&newtab=false&ot_source=Restaurant%20website&cfe=true";
    //     document.body.appendChild(script);
    // }
}
</script>