
<template>
    <div class="container-fluid">
        <div class="row border-top">
            <div class="col-12 mb-4">
                <h5 class="mb-3 text-center caligraphy mt-2">Elevate Your Event with Authentic Greek Flavors</h5>
                <p class="mt-4"><i>- Small pan serves 6-10 people <br>- Large pan serves 14-16 people</i></p>
            </div>

            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center caligraphy">· SMALL ·</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center caligraphy">· LARGE ·</p>
            </div>
            <div class="col-lg-1"></div>            
            
            <!-- Caesar Salad --><div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Caesar Salad</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">50</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">90</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Village Salad -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Village Salad</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">60</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">110</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Tossed Salad -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Tossed Salad</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">45</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">75</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Cheese Platter -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Cheese Platter</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">85</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">160</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Oven Roasted Potatoes -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Oven Roasted Potatoes</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">45</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">75</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Mashed Potatoes -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Mashed Potatoes</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">45</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">75</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Rice Pilaf -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Rice Pilaf</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">45</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">75</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Broccolini -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Broccolini</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">45</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">75</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Honey Glazed Carrots -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Honey Glazed Carrots</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">45</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">75</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Sauteed Vegetables -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Sauteed Vegetables</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">45</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">75</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Loukaniko Sausage & Peppers -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Loukaniko Sausage & Peppers</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">95</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">160</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Eggplant Papoutsakia -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Eggplant Papoutsakia</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">90</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">160</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Mousaka -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Mousaka</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">85</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">150</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Pastitsio -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Pastitsio</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">85</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">150</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Kotopoulo (Chicken Skewers) -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Kotopoulo (Chicken Skewers)</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">95</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">160</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Pork Kontosouvli -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Pork Kontosouvli</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">-</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">-</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Prime Rib Roast (15 lbs) -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Prime Rib Roast (15 lbs)</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">360</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">-</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Mediterranean Salmon with Orzo -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Mediterranean Salmon with Orzo</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">110</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">190</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Lamb Chops (Per Dozen) -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Lamb Chops (Per Dozen)</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">120</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">-</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Shrimp Cocktail (50 pcs) -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Shrimp Cocktail (50 pcs)</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">230</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">-</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Kataifi Shrimp (50 pcs) -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Kataifi Shrimp (50 pcs)</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">240</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">-</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Spanakopita Triangles (Per Dozen) -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Spanakopita Triangles (Per Dozen)</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">60</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">-</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Crab Cakes -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Crab Cakes</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">85</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">160</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Lamb Meatballs (Per Dozen) -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Lamb Meatballs (Per Dozen)</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">60</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">-</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Greek Penne Bolognese -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Greek Penne Bolognese</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">95</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">170</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Lamb Sliders (Per Dozen) -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Lamb Sliders (Per Dozen)</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">85</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">-</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Dolmades (Per Dozen) -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Dolmades (Per Dozen)</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">40</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">-</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Falafel (Per Dozen) -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Falafel (Per Dozen)</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">40</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">-</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Mix Olives -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Mix Olives</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">45</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">80</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Tzatziki (1/2 Pint - Full Pint) -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Tzatziki (1/2 Pint - Full Pint)</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">8</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">15</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Spicy Feta (1/2 Pint - Full Pint) -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Spicy Feta (1/2 Pint - Full Pint)</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">8</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">15</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Hummus (1/2 Pint - Full Pint) -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Hummus (1/2 Pint - Full Pint)</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">8</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">15</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Pita Bread (Per Dozen) -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Pita Bread (Per Dozen)</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">30</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">-</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Baklava Rolls (Per Dozen) -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Baklava Rolls (Per Dozen)</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">25</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">-</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Bougatsa -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Bougatsa</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">30</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">50</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Grilled Pita Sandwiches (Skepasti) -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3 mt-5">
                <p><u>Grilled Pita Sandwiches (Skepasti)</u></p>
            </div>
            <div class="col-lg-2 col-3 mt-3"></div>
            <div class="col-lg-2 col-3 mt-3"></div>
            <div class="col-lg-1"></div>


            <!-- Pork Skepasti -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Pork Skepasti</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">60</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">110</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Chicken Skepasti -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Chicken Skepasti</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">60</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">110</p>
            </div>
            <div class="col-lg-1"></div>

            <!-- Veggie Skepasti -->
            <div class="col-lg-1"></div>
            <div class="col-6 mt-3">
                <p>Veggie Skepasti</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">50</p>
            </div>
            <div class="col-lg-2 col-3 mt-3">
                <p class="mb-3 text-center">95</p>
            </div>
            <div class="col-lg-1"></div>


        </div>

    </div>
</template>

<script>
export default {
    name: 'DinnerMenu'
}
</script>